<template>
  <div class="p-2" v-loading="isLoading">
    <div v-for="(document, k) in profile.documents">
      <b-row>
        <b-col lg="3">
          <b-form-group :label="$t('Document Type')">
            <template slot="label">
              <span class="required" v-if="document.isRequired">*</span>
              {{ $t('Document Type') }}
            </template>
            <el-input size="mini" v-model="document.type" :readonly="document.isRequired" readonly="readonly"/>
          </b-form-group>
        </b-col>
        <b-col lg="2">
          <b-form-group :label="$t('Valid Date')">
            <el-input size="mini" type="number" min="0" max="3650" v-model="document.validDays" readonly="readonly"/>
          </b-form-group>
        </b-col>
        <b-col lg="2">
          <b-form-group>
            <el-checkbox class="mt-2" size="mini" v-model="document.isSubsequentDocument" disabled="disabled">Subsequent</el-checkbox>
          </b-form-group>
        </b-col>
        <b-col lg="5" align="right">
          <b-form-group>
            <el-button class="mt-1 text-ellipsis-button" size="mini" readonly="readonly" @click="openDocument(document)">{{ document.clientFilename ? document.clientFilename : 'No File' }}</el-button>
          </b-form-group>
        </b-col>
      </b-row>
      <hr/>
    </div>
  </div>
</template>

<script>
import { BCard, BCardText } from "bootstrap-vue";
import File from "@/views/erp/mixins/File";
import Prompt from "@/views/erp/mixins/Prompt";

export default {
  mixins: [File, Prompt],
  components: {
    BCard,
    BCardText,
  },
  props: {
    profile: Object,
  },
  data(){
    return {
      isLoading: false,
    }
  },
  methods: {
    openDocument(document) {
      this.downloadFile('ORGANIZATION_PROFILE', document.serverFilename, document.clientFilename);
    },
  }
}
</script>
