<template>
  <div class="p-2">
    <el-form :model="profile" ref="profileForm" class="profile compact">
      <b-row>
        <b-col lg="3">
          <h3>
            Company Detail
          </h3>

          <el-form-item :label="$t('Company Name (Invoice)')">
            <el-input size="mini" v-model="profile.companyName" readonly="readonly"/>
          </el-form-item>

          <el-form-item :label="$t('Company Name (System)')">
            <el-input size="mini" v-model="profile.companyNameInternal" readonly="readonly"/>
          </el-form-item>

          <el-form-item :label="$t('Company Address')">
            <el-input size="mini" v-model="profile.companyAddress" readonly="readonly"/>
          </el-form-item>

          <el-form-item :label="$t('BR Number (For HK Company)')">
            <el-input size="mini" v-model="profile.brNumber" readonly="readonly"/>
          </el-form-item>

          <el-form-item :label="$t('Company Type')">
            <el-input size="mini" v-model="profile.companyType" readonly="readonly"/>
          </el-form-item>

          <el-form-item :label="$t('Payment Terms (Days)')">
            <el-input size="mini" v-model="profile.paymentTermDays" readonly="readonly"/>
          </el-form-item>

          <el-form-item :label="$t('Key Account')">
            <el-input size="mini" v-model="profile.keyAccount" readonly="readonly"/>
          </el-form-item>

          <el-form-item :label="$t('Credit Amount')">
            <el-input size="mini" v-model="profile.creditAmount" readonly="readonly"/>
          </el-form-item>

          <el-form-item :label="$t('Contact End Date')">
            <el-input size="mini" v-model="profile.contractEndDate" readonly="readonly"/>
          </el-form-item>

          <el-form-item :label="$t('Contact Inactive')">
            <div style="clear: both">
              <el-checkbox size="mini" v-model="profile.contractEndInactive" disabled/>
            </div>
          </el-form-item>
        </b-col>

        <b-col lg="3">
          <h3>
            &nbsp;
          </h3>

          <el-form-item :label="$t('ERP Account Code')">
            <el-input size="mini" v-model="profile.accountCode" readonly="readonly"/>
          </el-form-item>

          <el-form-item :label="$t('Country')">
            <el-input size="mini" v-model="profile.country" readonly="readonly"/>
          </el-form-item>

          <el-form-item :label="$t('Corporate Registration Number')">
            <el-input size="mini" v-model="profile.corporateRegistrationNumber" readonly="readonly"/>
          </el-form-item>

          <el-form-item :label="$t('Contract Type')">
            <el-input size="mini" v-model="profile.contractType" readonly="readonly"/>
          </el-form-item>

          <el-form-item :label="$t('Payment Currency')">
            <el-input size="mini" v-model="profile.paymentCurrency" readonly="readonly"/>
          </el-form-item>

          <el-form-item :label="$t('Tags')">
            <el-input size="mini" v-model="profile.tags" readonly="readonly"/>
          </el-form-item>

          <el-form-item :label="$t('ICP Number')">
            <el-input size="mini" v-model="profile.icpNumber" readonly="readonly"/>
          </el-form-item>

          <el-form-item :label="$t('Credit Amount Alert Emails')">
            <el-input size="mini" v-model="profile.creditAmountAlertEmails" readonly="readonly"/>
          </el-form-item>

          <el-form-item :label="$t('Contract End Alert Emails')">
            <el-input size="mini" v-model="profile.contractEndAlertEmails" readonly="readonly"/>
          </el-form-item>
        </b-col>

        <b-col lg="6">
          <h3>
            Company Contacts
          </h3>

          <div class="box-card" v-for="(companyContact, k) in profile.companyContacts">
            <b-row>
              <b-col lg="4">
                <b-form-group :label="$t('Name')">
                  <template slot="label">
                    <span class="required" v-if="companyContact.isRequired">*</span>
                    {{ $t('Name') }}
                  </template>
                  <el-input size="mini" v-model="companyContact.name" readonly="readonly"/>
                </b-form-group>
              </b-col>

              <b-col lg="4">
                <b-form-group :label="$t('Phone')">
                  <template slot="label">
                    <span class="required" v-if="companyContact.isRequired">*</span>
                    {{ $t('Phone') }}
                  </template>
                  <el-input size="mini" v-model="companyContact.phone" readonly="readonly"/>
                </b-form-group>
              </b-col>

              <b-col lg="4">
                <b-form-group :label="$t('Email')">
                  <template slot="label">
                    <span class="required" v-if="companyContact.isRequired">*</span>
                    {{ $t('Email') }}
                  </template>
                  <el-input size="mini" v-model="companyContact.email" readonly="readonly"/>
                </b-form-group>
              </b-col>
            </b-row>
          </div>

          <hr/>

          <h3>
            F&amp;A Contacts
          </h3>
          <div class="box-card" v-for="(faContact, k) in profile.faContacts">
            <b-row>
              <b-col lg="4">
                <b-form-group :label="$t('Name')">
                  <template slot="label">
                    <span class="required" v-if="faContact.isRequired">*</span>
                    {{ $t('Name') }}
                  </template>
                  <el-input size="mini" v-model="faContact.name" readonly="readonly"/>
                </b-form-group>
              </b-col>

              <b-col lg="4">
                <b-form-group :label="$t('Phone')">
                  <template slot="label">
                    <span class="required" v-if="faContact.isRequired">*</span>
                    {{ $t('Phone') }}
                  </template>
                  <el-input size="mini" v-model="faContact.phone" readonly="readonly"/>
                </b-form-group>
              </b-col>

              <b-col lg="4">
                <b-form-group :label="$t('Email')">
                  <template slot="label">
                    <span class="required" v-if="faContact.isRequired">*</span>
                    {{ $t('Email') }}
                  </template>
                  <el-input size="mini" v-model="faContact.email" readonly="readonly"/>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
    </el-form>
  </div>
</template>

<script>
import { BCard, BCardText } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardText,
  },
  props: {
    profile: Object,
  },
}
</script>
