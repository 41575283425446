<template>
  <InvoiceList :profile="profile" :type="type"/>
</template>

<script>
import InvoiceList from "@/views/erp/InvoiceList.vue";

export default {
  components: {
    InvoiceList,
  },
  props: {
    profile: Object,
    type: String,
  }
}
</script>
