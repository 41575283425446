<template>
  <div class="p-2">
    <table class="w-100">
      <tr>
        <th class="py-1">Category</th>
        <th class="py-1">Description</th>
        <th class="py-1">Charge Code</th>
      </tr>
      <tr v-for="chargeCode in profile.chargeCodes" v-if="chargeCodeMap[chargeCode]">
        <td class="py-1">{{ chargeCodeMap[chargeCode].category }}</td>
        <td class="py-1">{{ chargeCodeMap[chargeCode].remark }}</td>
        <td class="py-1">{{ chargeCodeMap[chargeCode].code }}</td>
      </tr>
    </table>
  </div>
</template>

<script>
import { BCard, BCardText } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardText,
  },
  props: {
    profile: Object,
    chargeCodes: Array,
  },
  data() {
    return {
    }
  },
  mounted() {
    //
  },
  methods: {
    //
  },
  computed: {
    chargeCodeMap: function() {
      let map = {};

      this.chargeCodes.forEach(row => {
        map[row.code] = row;
      })

      return map;
    }
  }
}
</script>
